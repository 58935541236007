import React from 'react';
import { graphql } from 'gatsby';

import Website from 'components/ui-setup';
import Layout from 'components/layout';
import Careers from 'components/careers';

type Props = {
  data: Object,
};

const CareersPage = ({ data }: Props) => {
  const {
    careersJson,
    currentOpeningsJson,
    siteJson,
    logoJson,
    socialMediaJson,
    cookieConsentJson,
  } = data;

  return (
    <Website
      content={{
        ...careersJson,
        currentOpenings: currentOpeningsJson,
        cookieConsent: cookieConsentJson,
        menu: siteJson?.content,
        logo: logoJson,
        socialMedia: socialMediaJson,
      }}>
      <Layout>
        <Careers />
      </Layout>
    </Website>
  );
};

export default CareersPage;

export const query = graphql`
  query CareersPageQuery {
    careersJson {
      hero {
        background {
          image {
            xl
            lg
            md
            sm
            xs
          }
        }
        title
      }
      life {
        title
        subtitle
        text
        buttonLabel
        buttonLink
      }
      achieve {
        title
        cards {
          id
          title
          content
          image
          imageSize
        }
      }
      slider {
        images {
          xl
          lg
          md
          sm
          xs
        }
      }
      meta {
        description
        title
      }
    }
    siteJson {
      content {
        whoWeAre {
          id
          title
          type
          position
          visible
          menuItems {
            ourLeadership {
              id
              link
              title
              type
              visible
            }
            vision {
              id
              link
              title
              type
              visible
            }
          }
        }
        whatWedo {
          id
          title
          type
          position
          visible
          menuItems {
            industries {
              id
              link
              title
              type
              visible
            }
            models {
              id
              link
              title
              type
              visible
            }
            practices {
              id
              link
              title
              type
              visible
            }
          }
        }
        techProfessionals {
          id
          link
          position
          title
          type
          visible
        }
        careers {
          id
          link
          position
          title
          type
          visible
        }
        blog {
          id
          link
          position
          title
          type
          visible
        }
        contact {
          id
          link
          position
          title
          type
          visible
        }
      }
    }
    currentOpeningsJson {
      background {
        image {
          xs
          sm
          md
          lg
          xl
        }
      }
      title
      text
      buttonLink
      buttonLabel
    }
    cookieConsentJson {
      message
      buttons {
        accept
        reject
      }
    }
    logoJson {
      colorful
      white
    }
    socialMediaJson {
      label
      media {
        id
        image
        url
      }
    }
  }
`;
